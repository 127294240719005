'use client'

import Button from '@licommon/components/Button'
import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import 'swiper/css/navigation'
import { Controller, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Overlay } from './Overlay'
import { PaginationArrows } from './PaginationArrows'
import { PaginationDots } from './PaginationDots'
import { THeroBanner } from './THeroBanner'

export default function HeroBannerSlider({
  items,
  rounded = false,
  centered = false,
}: {
  items: THeroBanner[]
  rounded?: boolean
  centered?: boolean
}) {
  const swiperRef = useRef<any>(null)
  const [canSlidePrev, setCanSlidePrev] = useState(false)
  const [canSlideNext, setCanSlideNext] = useState(true)
  const { isLI, isCFD } = getPortalType()
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', updatePrevNext)
    return () => window.removeEventListener('resize', updatePrevNext)
  }, [])

  function updatePrevNext() {
    setCanSlidePrev(swiperRef.current?.activeIndex > 0)
    setCanSlideNext(
      swiperRef.current?.activeIndex +
        swiperRef.current?.visibleSlides?.length <
        items?.length,
    )
  }

  useEffect(() => updatePrevNext(), [])

  return (
    <div
      className="w-full flex-1 relative flex flex-col"
      data-testid="HeroBannerSlider"
    >
      <div
        className={clsx(
          'relative mx-auto flex flex-1 w-full',
          rounded && 'rounded-md',
        )}
      >
        <Swiper
          slidesPerView={1}
          onBeforeInit={(swiper) => (swiperRef.current = swiper)}
          modules={[Navigation, Controller]}
          onSlideChange={(data) => {
            setCurrentIndex(data.realIndex)
            setTimeout(() => updatePrevNext(), 0)
          }}
          watchSlidesProgress
          className="w-full"
        >
          {items?.map((item, i) => {
            const loading = i === 0 ? 'eager' : 'lazy'
            return (
              <SwiperSlide key={i}>
                <div
                  className={clsx(
                    'w-full flex flex-col lg:flex-row flex-1 relative h-full',
                  )}
                >
                  <div
                    className={clsx(
                      'flex-1 md:flex-0 bg-blue-700 w-full flex flex-col p-3 xl:p-4 2xl:p-6 justify-center md:h-auto',
                      isCFD && 'md:min-w-[360px]',
                      isLI && 'md:min-w-[450px]',
                    )}
                    style={{ background: item.background }}
                  >
                    {!item.infoImage && (
                      <div className="h-[60px] md:h-[70px] lg:h-[170px] w-full"></div>
                    )}
                    <div
                      style={{ color: item.color, fontSize: `${item?.textStyle?.fontSize ? `${item.textStyle.fontSize}px` : '40px'}`}}
                      className={clsx(
                        'text-center uppercase md:capitalize text-white font-bold ',
                        centered ? 'text-center' : 'lg:text-left',
                        isLI && 'md:mb-2 lg:max-w-[70%]',
                        isCFD && 'mb-2',
                      )}
                    >
                      <div>{item.text}</div>
                    </div>
                    {item.infoImage ? (
                      <div
                        className={clsx(
                          'max-h-[340px] w-full flex',
                          centered
                            ? 'justify-center'
                            : 'justify-center lg:justify-start',
                        )}
                      >
                        <Image
                          width={340}
                          height={340}
                          className={clsx(
                            'object-contain rounded-md h-[200px] w-[200px] md:h-[200px] md:w-[200px] lg:h-[340px] lg:w-[340px]',
                            isCFD && 'object-center',
                            isLI && 'object-center md:object-left',
                          )}
                          alt="image"
                          src={item.infoImage.url}
                          {...{ loading }}
                        />
                      </div>
                    ) : null}
                    <div
                      className={clsx(
                        'mt-4 flex',
                        centered ? 'justify-center' : 'justify-start',
                      )}
                    >
                      <Button
                        flat
                        style={{
                          backgroundColor: item.button.backgroundColor,
                          color: item.button.color,
                        }}
                        href={item.link}
                        variant="success"
                        className="w-full lg:w-auto font-medium !inline-block rounded-md"
                      >
                        {item.button.text}
                      </Button>
                    </div>
                    {!item.infoImage && (
                      <div className="h-[60px] md:h-[70px] lg:h-[170px] w-full"></div>
                    )}
                  </div>
                  {item.image && (
                    <div className="flex-1 overflow-hidden max-h-[325px] md:max-h-[500px]  lg:max-h-none md:flex-1 relative">
                      <img
                        className="object-cover object-top h-full w-full"
                        alt="image"
                        src={item.image.url}
                        {...{ loading }}
                        style={{aspectRatio:'16/10'}}
                      />
                      <Overlay className="hidden md:block" item={item} />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className={clsx('w-full flex-1', isLI && 'hidden md:block')}>
          <PaginationArrows
            {...{ swiperRef, canSlideNext, canSlidePrev, items }}
          />
        </div>
      </div>

      {isLI && <PaginationDots active={currentIndex} total={items.length} />}
    </div>
  )
}
