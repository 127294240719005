'use client'
import Image from 'next/image'
import { ComponentData } from '../types'
import { getPortalType } from '../utils/portal'
import './../styles/components/sections/logo-section.scss'
import ComponentSlider, { SliderConfig } from './ComponentSlider'
import Link from 'next/link'

export default function SupplierListCarousel({
                                                 data: {title, suppliers = []},
                                                 imageCardType,
                                             }: {
    data: ComponentData
    imageCardType?: 'overlay' | 'card'
}) {
    const brandWithImages = suppliers.filter((s) => s.logo != null)
    brandWithImages.concat(suppliers.filter((s) => s.logo != null))
    const { isCALIBO } = getPortalType()
    return (
        <div>
            { !isCALIBO && (
                <ComponentSlider
                    config={new SliderConfig((width) => {
                            if (!width) return 6
                            if (width < 600) return 2
                            if (width < 900) return 3
                            if (width < 1200) return 4
                            if (width < 1600) return 5
                            return 6
                        },
                        "center")
                    }
                    totalCount={brandWithImages.length}
                    title={title || ''}
                >
                    {brandWithImages.map((supplier, index) => (
                        <div
                            className="p-2 border md:border-2 rounded-md flex justify-center items-center"
                            data-index={index}
                            key={supplier.name}
                        >
                            <Link href={`/supplier/${supplier.slug}`}>
                                <Image
                                    height={500}
                                    width={500}
                                    className="h-100 w-100 object-contain object-center max-h-24"
                                    alt={supplier.name}
                                    src={supplier.logo}
                                    loading="eager"
                                    priority={true}
                                />
                            </Link>
                        </div>
                    ))}
                </ComponentSlider>
            )}

            { isCALIBO && (
                <section className="logo-section__wrapper">
                    <div className="logo-section__container">
                        <ComponentSlider
                            config={new SliderConfig((width) => {
                                    if (!width) return 6
                                    if (width < 600) return 2
                                    if (width < 900) return 3
                                    if (width < 1200) return 4
                                    if (width < 1600) return 5
                                    return 6
                                },
                                "center")
                            }
                            totalCount={brandWithImages.length}
                            title={title || ''}
                        >
                            {brandWithImages.map((supplier, index) => (
                                <div
                                    className="logo"
                                    data-index={index}
                                    key={supplier.name}
                                >
                                    <Image
                                        height={200}
                                        width={200}
                                        className="h-100 w-100 object-contain object-center max-h-24"
                                        alt={supplier.name}
                                        src={supplier.logo}
                                        loading="eager"
                                        priority={true}
                                    />
                                </div>
                            ))}
                        </ComponentSlider>
                    </div>
                </section>
            )}
        </div>

    )
}
