import Image from 'next/image'
import Link from 'next/link'
import { getPortalType } from '../../utils/portal'
import { ImageCardProps } from './ImageCardProps'

const ImageCardCapsule = (props: ImageCardProps) => {
  const {
    image,
    title,
    className = '',
    slug,
    alt,
    type = process.env.NEXT_PUBLIC_SITE_ID === '2' ? 'card' : 'overlay',
  } = props

  const href = `${slug}`
  const {isCALIBO} = getPortalType();

  return (
    <Link href={href.replaceAll('category/category', 'category')}>
      <div
        className={`flex border ${
          type === 'card' ? 'rounded-lg' : 'rounded-full'
        } border-gray-300 p-3`}
      >
        <div className="h-[40px] w-[40px] rounded-full overflow-hidden flex-shrink-0">
          <Image
            className="object-cover w-full h-full object-center "
            height={40}
            width={40}
            alt={alt}
            src={image}
            loading="eager"
            priority={true}
          />
        </div>
        <div className={`${isCALIBO ? 'heading ' : ''}text-[0.77rem] flex items-center text-center text-gray-600 font-medium ml-3`}>
          {title}
        </div>
      </div>
    </Link>
  )
}

export default ImageCardCapsule
