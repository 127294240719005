'use client'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { alertSuccess } from '../utils/alert'

const HomeAlert = () => {
  const session = useSession()
  const router = useRouter()
  useEffect(() => {
    if (session.data?.user && session.status == 'authenticated') {
      alertSuccess('You have successfully logged in')
      router.replace('/')
    }
  }, [session.status])

  return <></>
}

export default HomeAlert
