'use client'
import { TPosition } from './THeroBanner'

export const getClassForPosition = (position: TPosition) => {
  switch (position) {
    case 'bottom-left':
      return 'bottom-3 left-3'
    case 'bottom-right':
      return 'bottom-3 right-3'
    case 'top-left':
      return 'top-3 left-3'
    case 'top-right':
      return 'top-3 right-3'
  }
}
