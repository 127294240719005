'use client'

import Link from 'next/link'
import { Controller, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ComponentData } from '../../types'
import { getPortalType } from '../../utils/portal'
import ImageCardCapsule from './ImageCardCapsule'

export default function ImageCardCapsuleCarousel({
  data,
}: {
  data: ComponentData
}) {
  const { title, meta } = data
  const {isCALIBO} = getPortalType();

  return (
    <div
      className={`${isCALIBO ? 'calibo_capsule-slider ' : ''}flex flex-col md:pl-0`}
      data-testid="ImageCardCapsuleCarousel"
    >
      <div className="mb-5">
        <div className="font-medium px-3 text-blue-700 text-center text-3xl">
          {title}
        </div>
        {meta.full_url && (
          <Link
            className="flex mt-1 justify-center font-medium text-md text-green-700 underline text-center"
            href={meta.full_url}
            type="secondary"
          >
            View All
          </Link>
        )}
      </div>
      <Swiper
        spaceBetween={10}
        watchSlidesProgress
        modules={[Controller, Navigation]}
        className="max-w-full !mx-0 md:mx-auto"
        breakpoints={{
          320: { slidesPerView: 1.6 },
          480: { slidesPerView: 2.2 },
          640: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
          1336: { slidesPerView: 4 },
        }}
      >
        {meta.images.map((image, index) => (
          <SwiperSlide key={index} className="">
            <ImageCardCapsule
              key={image.image?.name}
              image={image.image?.url}
              title={image.title}
              slug={image.link}
              alt={image.alt}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
