'use client'

import { clsx } from '@licommon/utils/clsx'

export const PaginationDots = ({
  total,
  active,
}: {
  active: number
  total: number
}) => {
  return (
    <div className="w-full flex md:hidden justify-center gap-1 mt-3">
      {Array.from({ length: total }).map((val, index) => {
        return (
          <div
            key={index}
            className={clsx(
              'bg-gray-300 h-[10px] w-[10px] rounded-full',
              active === index ? 'bg-gray-600' : '',
            )}
          ></div>
        )
      })}
    </div>
  )
}
