'use client'
import { clsx } from '@licommon/utils/clsx'
import { THeroBanner } from './THeroBanner'
import { getClassForPosition } from './getClassForPosition'

export const Overlay = ({
  item,
  className = '',
}: {
  item: THeroBanner
  className: string
}) => {
  return item?.overlay && item.overlay?.text?.length && (
    <div
      dangerouslySetInnerHTML={{
        __html: item.overlay.text.split(' ').join('<br/>'),
      }}
      className={clsx(
        'absolute bg-danger aspect-square flex justify-center items-center p-3 sm:p-4 md:p-4 rounded-full text-xxs sm:text-xs md:text-sm xl:text-xl capitalize text-white font-bold',
        getClassForPosition(item.overlay.position),
        className,
      )}
      style={{
        aspectRatio: '1 / 1',
        minHeight: 0,
        display: 'flex',
        padding: '2em',
        backgroundColor: item.overlay.backgroundColor,
      }}
    />
  )
}
