'use client'

import { clsx } from '@licommon/utils/clsx'
import { CaretLeft, CaretRight } from './Icons'
import { THeroBanner } from './THeroBanner'

export const BTN_CLASS =
  'h-10 w-10 flex justify-center items-center rounded-full border-2 border-gray-300 text-gray-400 bg-gray-100'
export const ACTIVE_BTN_CLASS =
  'border-gray-400 text-gray-500 cursor-pointer bg-white'

export function PaginationArrows({
  items,
  canSlideNext,
  canSlidePrev,
  swiperRef,
}: {
  items: THeroBanner[]
  canSlideNext: boolean
  canSlidePrev: boolean
  swiperRef: any
}) {
  if (items.length < 1) return
  return (
    <div
      className="w-full md:w-auto justify-between md:justify-center flex gap-1 items-center absolute px-2 md:px-0 bottom-4 right-0 md:right-4 z-[2]"
      style={{ userSelect: 'none' }}
    >
      <div
        onClick={() => swiperRef.current?.slidePrev()}
        className={clsx(BTN_CLASS, canSlidePrev && ACTIVE_BTN_CLASS)}
      >
        <CaretLeft size={18} />
      </div>
       
      <div
        onClick={() => swiperRef.current?.slideNext()}
        className={clsx(BTN_CLASS, canSlideNext && ACTIVE_BTN_CLASS)}
      >
        <CaretRight size={18} />
      </div>
    </div>
  )
}
