import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import Image from 'next/image'
import Link from 'next/link'
import { ImageCardProps } from './ImageCardProps'

export default function ImageCard({
  image,
  title,
  className = '',
  imageClass = '',
  slug,
  alt,
  type = process.env.NEXT_PUBLIC_SITE_ID === '2' ? 'card' : 'overlay',
  imageHeight = '',
  index = 0,
  full = true,
  isBlogPage = false,
  ...props
}: ImageCardProps) {
  const { isCFD, isCALIBO } = getPortalType()
  const classPrefix = isCALIBO ? 'calibo_' : isCFD ? 'cfd_' : 'li_'

  function element() {
    return (
      <Link href={`${slug}`} {...props}>
        <div
          data-index={index}
          className={`${
            type === 'card'
              ? `border border-gray-300 rounded px-1 pt-1 ${
                  full && !isCFD ? 'h-full' : ''
                }`
              : ''
          }`}
        >
          <div
            className={`relative image-card group rounded overflow-hidden cursor-pointer ${
              full && !isCFD ? 'h-full' : ''
            } w-full ${className}`}
          >
            <div
              className={`${imageHeight} md:aspect-auto-[0.95] overflow-hidden ${
                type === 'card' ? 'rounded' : 'rounded-lg aspect-square'
              }`}
            >
              <Image
                className={clsx(
                  imageClass,
                  `group-hover:scale-105 transition-all ease-out object-cover ${
                    full ? 'h-full' : ''
                  } w-full`,
                )}
                height={500}
                width={400}
                alt={alt}
                src={image}
                loading="lazy"
                quality={90}
              />
            </div>
            {!isCALIBO && (
              <div
                className={`${
                  type === 'card' ? '' : 'absolute'
                } mx-auto left-0 right-0 flex justify-center bottom-5`}
              >
                <div
                  className={`flex ${
                    type === 'card'
                      ? 'w-full font-medium'
                      : 'mx-2 rounded-full bg-white border border-gray-300 md:text-sm text-[.8rem]'
                  } items-center justify-center md:py-[10px] py-[7px] md:px-4 px-[.7rem] md:text-md text-gray-600 text-center `}
                >
                  {title}
                </div>
              </div>
            )}
          </div>
          {isCALIBO && (
            <div className={`${classPrefix}card-content-section`}>
              <h5>{title}</h5>
              <a href={`${slug}`}>
                {isCALIBO && isBlogPage ? 'Read article' : 'Learn More'}
              </a>
            </div>
          )}
        </div>
      </Link>
    )
  }

  return slug ? (
    <Link href={`${slug}`} {...props}>
      {element()}
    </Link>
  ) : (
    element()
  )
}
