'use client'
import ComponentSlider, { SliderConfig } from '@licommon/components/ComponentSlider'
import { ComponentData } from '../../types'
import ImageCard from '../image-card/ImageCard'

import '../../styles/components/image-card-carousel.scss'
import { getPortalType } from '../../utils/portal'

export default function ImageCardCarousel({
  data: { title, meta },
  imageCardType,
  sliderNavClassName = '',
  sliderPrevButtonClass = '',
  sliderNextButtonClass = '',

}: {
  data: ComponentData
  imageCardType?: 'overlay' | 'card'
  sliderNavClassName?: string
  sliderPrevButtonClass?: string
  sliderNextButtonClass?: string
}) {
  const { isCALIBO } = getPortalType()
  const { images } = meta
  return (
    <ComponentSlider
      config={
        new SliderConfig((width) => {
          if (!width) return 4
          if (width < 400) return 2
          if (width < 1024) return 3
          return isCALIBO ? 3 : 4
        })
      }
      totalCount={images.length}
      title={title || ''}
      navClassName={sliderNavClassName}
      prevButtonClass={sliderPrevButtonClass}
      nextButtonClass={sliderNextButtonClass}
    >
      {images.map((image, index) => (
        <ImageCard
          data-index={index}
          key={image.image?.url}
          image={image.image?.url}
          title={image.title}
          slug={image.link}
          alt={image.alt}
          className="max-w-[unset] image-card"
          type={imageCardType}
        />
      ))}
    </ComponentSlider>
  )
}
